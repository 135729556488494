<template>
  <div class="kecheng">
    <img v-if="imgList.img" class="tui-img" :src="imgList.img" alt="">
    <div class="kmain">
      <kind-choose-view :show-kc="false" :type="116" :if-img="false" @isOk="isOk" />

      <div class="zblist">
        <div v-for="(item, index) in list" :key="index" @click="godetail(item)">
          <div class="t_img">
            <img :src="item.img" alt="">
            <div class="rq">{{ item.clicks ? item.clicks : 0 }}人气值</div>
            <div class="relogo" />
          </div>
          <div class="t_til">
            <div class="tname">{{ item.terName }}</div>
            <div class="ttil">{{ item.title }}</div>
            <!-- <div class="zbbtn">进入直播</div> -->
            <div class="kbsj">
              {{ item.startTime | format("yyyy-MM-dd HH:mm") }}
            </div>
          </div>
          <div class="timg">
            <div class="terbox">
              <el-image class="ter-img" :src="item ? item.tImg : ''" fit="cover" />
            </div>
          </div>
        </div>
      </div>

      <!-- 暂无课程 -->
      <div v-if="!list || list.length == 0" class="nothing">
        <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
        <div class="nothing-text">暂无内容哦~</div>
      </div>
      <div class="flex-jc-c m-t-20">
        <el-pagination :total="total" :current-page="pageNum" :page-sizes="[15, 30, 45, 60]" :page-size="pageSize"
          :pager-count="15" layout="total, sizes, prev, pager, next, jumper" @current-change="handleCurrentChange"
          @size-change="handleSizeChange" />
      </div>
    </div>
    <el-dialog title="提示" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false"
      :visible.sync="ifHave" width="400px" center>
      <span>尚未购买无法观看</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ifHave = false">取消</el-button>
        <el-button type="primary" @click="goOrder">去购买</el-button>
      </span>
    </el-dialog>
    <!-- 是否可以观看弹窗 -->
    <div v-if="showLookDetail" class="lookBg">
      <div class="ifLook">
        <div class="prompt">提示</div>
        <div class="contents">
          您当前正在<span>{{ lookDeatil ? lookDeatil.terminal : "" }}</span>观看<span>{{ lookDeatil ? lookDeatil.chapterName :
            ""
            }}</span>,请关闭后再行观看其他章节。
        </div>
        <div class="button" @click="closeLook">关闭</div>
      </div>
    </div>
  </div>
</template>
<script>
import KindChooseView from '../../components/kindChooseViewzb.vue'
import Bus from '@/api/bus'
import Vue from 'vue'
import { Know, courseCheckPlay } from '@/api/know'
const know = new Know()
import { getInfo, getDomain } from '@/api/cookies'
import { getSurfaceList } from '@/api/home'
import { getLbClassCourseById } from '@/api/home'
import { OrderClient } from '@/api/orderClient'
const orderClient = new OrderClient()
export default {
  components: { KindChooseView },
  data() {
    return {
      search: {},
      list: [],
      total: 0,
      userInfo: null,
      pageSize: 15,
      pageNum: 1,
      loading: null,
      ifHave: false,
      liveId: null,
      liveshowName: '',
      live: null,
      imgList: {},
      domain: null,
      lookDeatil: null,
      showLookDetail: false
    }
  },
  async beforeDestroy() {
    this.loading.close()
  },
  created() {
    this.domain = getDomain()
    this.userInfo = getInfo()
    // 调用轮播图导航接口方法
    this.getList()
    this.getImg()
  },
  methods: {
    getImg() {
      getSurfaceList(4, undefined, this.domain.entrantsType).then((res) => {
        if (res.code == 0) {
          if (res.msg.length > 0) {
            this.imgList = res.msg[0]
          } else {
            this.imgList = {}
          }
        }
      })
    },
    // 获取列表
    async getList() {
      this.loading = this.$loading({
        lock: true,
        text: '加载中',
        spinner: '',
        background: 'rgba(0, 0, 0, 0.2)'
      })
      await know
        .getLiveshowplaybackByTypes(
          this.pageSize,
          this.pageNum,
          this.search.kind ? this.search.kind : null,
          this.search.zyId ? this.search.zyId : null
        )
        .then((res) => {
          this.total = res.total
          this.list = res.rows
        })
      this.loading.close()
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getList()
    },
    // 筛选
    isOk(val) {
      this.search = val
      this.getList()
    },
    // 直播课详情
    zhiboke(item) {
      this.$router.push(`/zhiboclassxq?id=${item.id}&&type=2`)
    },
    async godetail(item) {
      if (!this.userInfo) {
        Vue.prototype.goLoginView(true)
        return
      }
      this.liveId = item.liveId
      this.liveshowName = item.liveshowName
      await getLbClassCourseById(item.liveId).then((res) => {
        this.ifHave = !res.data.IsPurchase
        this.live = res.data.details
      })
      if (this.ifHave) {
        return
      }
      if (item.isLive == 0) {
        this.$message.error('课程还未到开播时间')
        return
      }
      if (item.isLive == 2) {
        this.$message.error('课程直播结束')
        return
      }
      // this.$router.push({
      //   path: '/livebofang',
      //   query: {
      //     id: item.id,
      //     startTime: item.startTime,
      //   },
      // })
      courseCheckPlay({
        courseType: '1',
        userId: this.userInfo.id,
        chapterId: item.id,
        source: 1
      }).then((res) => {
        if (res.code == 0) {
          const routeUrl = this.$router.resolve({
            path: `/livebofang`,
            query: {
              id: item.id,
              startTime: item.startTime,
              chapterName: item.title
            }
          })
          if (
            /Safari/.test(navigator.userAgent) &&
            !/Chrome/.test(navigator.userAgent)
          ) {
            window.location.href = routeUrl.href
          } else {
            window.open(routeUrl.href, '_blank')
          }
        } else {
          this.lookDeatil = res.msg
          this.showLookDetail = true
        }
      })
    },
    closeLook() {
      this.showLookDetail = false
    },

    // 立即购买
    goOrder() {
      if (this.userInfo) {
        orderClient
          .addOrders(
            this.liveId,
            this.userInfo.id,
            this.live.title,
            2,
            this.live.title,
            this.live.kind
          )
          .then((res) => {
            if (res.code == 0) {
              this.$router.push({
                name: '个人中心订单页',
                params: { orderNumber: res.msg }
              })
            } else {
              this.$message.error(res.msg)
            }
          })
      } else {
        Vue.prototype.goLoginView(true)
      }
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .kmain {
  width: 1220px;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  margin: 24px auto;
}

/deep/ .box-div {
  border-radius: 8px 8px 8px 8px;
}

/deep/ .zychoice {
  // height: 82px;
  // line-height: 82px;
  border-radius: 8px 8px 8px 8px;
  padding-left: 32px;
}

.kecheng {
  width: 100%;
  margin: 0 auto;
}

// 最近直播  内容
.zblist {
  width: 1220px;
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  margin-top: 40px;

  >div {
    width: 228px;
    height: 280px;
    position: relative;
    margin-right: 20px;
    margin-bottom: 20px;
    cursor: pointer;

    .t_img {
      width: 228px;
      height: 152px;
      border-radius: 8px 8px 0px 0px;
      opacity: 1;
      position: relative;

      img {
        width: 228px;
        height: 152px;
        border-radius: 8px 8px 0px 0px;
        background-size: cover;
      }

      .rq {
        // width: 87px;
        height: 22px;
        background: #000000;
        border-radius: 4px 4px 4px 4px;
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 8px;
        left: 16px;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 22px;
        padding-left: 18px;
        padding-right: 4px;
        overflow: hidden;
      }

      .relogo {
        width: 22px;
        height: 22px;
        background: url("~@/assets/img/homesixth/Group 3261.png");
        opacity: 1;
        position: absolute;
        top: 8px;
        left: 8px;
      }
    }

    .t_til {
      width: 228px;
      height: 128px;
      background: #ffffff;
      box-shadow: 0px 8px 16px 1px rgba(204, 204, 204, 0.2);
      border-radius: 0px 0px 8px 8px;
      opacity: 1;

      .tname {
        height: 24px;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        padding-top: 8px;
        margin-left: 56px;
      }

      .ttil {
        height: 44px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        overflow: hidden;
        padding-left: 12px;
        padding-right: 20px;
        margin-top: 20px;
        line-height: 22px;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
      }

      .zbbtn {
        width: 120px;
        height: 36px;
        background: #1061ff;
        box-shadow: 0px 4px 8px 1px rgba(16, 89, 197, 0.3);
        border-radius: 18px 18px 18px 18px;
        opacity: 1;
        cursor: pointer;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 36px;
        text-align: center;
        margin: 20px auto;
      }

      .kbsj {
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin-top: 12px;
        padding-left: 12px;
      }
    }

    .timg {
      width: 36px;
      height: 36px;
      background: #c4c4c4;
      opacity: 1;
      position: absolute;
      top: 144px;
      left: 12px;
      border-radius: 50%;

      .terbox {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        overflow: hidden;
      }

      .ter-img {
        width: 36px;
      }
    }
  }

  >div:nth-child(5n) {
    margin-right: 0;
  }
}

.tui-img {
  margin: 0;
  width: 100%;
}

/deep/ .el-pagination .active {
  background: #1061ff !important;
}

/deep/ .on {
  background: #ecf2ff !important;
  color: #1061ff !important;
}

.lookBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  .ifLook {
    width: 500px;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;

    .contents {
      width: 380px;
      margin: 20px auto;

      &>span {
        color: red;
      }
    }

    .button {
      width: 120px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      border-radius: 10px;
      border: 1px solid #ccc;
      cursor: pointer;
    }
  }
}
</style>
